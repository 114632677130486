import React, {Component} from 'react';

class DevBlog extends Component{

  render(){
    return(
        <div>

        </div>

    );
}
}
export default DevBlog;
