import React, {Component} from 'react';

class AboutMeComp extends Component{

  render(){
    return(
          <div className='aboutMeDiv'>
          <h3>Education</h3>

              <h5>Vancouver Film School (VFS) </h5>
                <p>Course: Programming for Games, Web, and Mobile applications
                This course was one year long where I learned how to program properly and learned over ten programming languages.</p>
              <h5>Ritsumeikan University</h5>
                <p>Course: Information systems, science, and engineering</p>
            <hr></hr>
        <h3>Awards</h3>

            <h5>Geek Squad Exemplified</h5>
              <p>Geek Squad exemplified is where the team works really well together providing expert customer service for a quarter of the fiscal year and my team got it,<br></br> and we got jackets and a plack to prove it.</p>
          <hr></hr>
        <h3>Hobbies</h3>

            <h5>Playing video games </h5>
            <h5>Making video games</h5>
            <h5>Learning Japanese</h5>
            <h5>Throwing playing cards</h5>
            <h5>Cooking</h5>
          <hr></hr>
        <h3>expertise/skills</h3>
          <h5>Gameplay mechanics creation</h5>
          <h5>Unity/ Unreal Engine 4</h5>
          <h5>C/C++/C#</h5>
          <h5>Web Development</h5>
          <h5>I can learn any language quite quickly</h5>
        <h3>Abroad experiences</h3>
          <h5>Lived in Canada for a year</h5>
          <h5>Moved to Japan and this is where I am currently studying</h5>
          <h5>Went to Bitsummit in Kyoto as my first gaming convention I went to</h5>
        </div>
    );
  }
}
export default AboutMeComp;
