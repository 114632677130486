import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import TormentLogo from '../images/TormentLogo.jpg';
import TormentLogo2 from '../images/Torment_logo.jpg';
import Finch from '../images/fin.jpg';
import DevBuild1 from '../images/devBuild1.jpg';
import DevBuild2 from '../images/devBuild2.jpg';
import DevBuild3 from '../images/devBuild3.jpg';



class ProjectCards extends Component {

render(){
    return(
        <div>

        </div>

    );
}
}
export default ProjectCards;
